::-webkit-scrollbar {
  width: 10px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html,
body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  cursor: default;
  margin: 0;
}

ul,
ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a,
button {
  color: inherit;
  cursor: pointer;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

button {
  border: 0;
}

input {
  outline: none;
}

span {
  cursor: default;
}

.fa {
  line-height: normal;
}
