@tailwind base;
@tailwind components;
@tailwind utilities;

/* GLOBALS */

span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button,
input,
label,
select,
textarea {
  @apply text-white;
}

.flex-center {
  @apply flex justify-center items-center;
}

/* PAGES */

.page-title {
  @apply text-page-title text-white font-bold text-center;
}

.dashboard-layout {
  border-top: 3px solid #202124;
  border-left: 3px solid #202124;
}

.scroll-temp::-webkit-scrollbar-thumb {
  background: #141518;
  border-radius: 5px;
}

.list-format {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.scroll-gutter {
  scrollbar-gutter: stable both-edges !important;
}

::-webkit-scrollbar-track {
  background: #141518;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #202124;
}
::-webkit-scrollbar-thumb:hover {
  background: #5865f2;
}

.scrollbar-select::-webkit-scrollbar-track,
.scrollbar-multiselect::-webkit-scrollbar-track {
  background: #141518;
}
.scrollbar-select::-webkit-scrollbar-thumb,
.scrollbar-multiselect::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #5865f2;
}

.truncate-custom {
  overflow: hidden;
  text-overflow: ellipsis;
}
