@use "reset.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#__next {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

// .page_title {
//     color: #fff;
//     font-size: calc(2em + 1vw);
//     font-weight: 800;
// }
